.SelectVehicle{
    
    a{
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ed3237;
        border-radius: var(--radius);
        color: #fff;
        position: sticky;
    }
    .text{
        color:#fff;
        display: flex;
        align-items: center;
        & > svg{
            margin-right: 10px;
            font-size: 1.3rem;
        }
    }
}