.ProductInfo {
  width: 100%;
  padding: 20px;
  @media (min-width: 992px) {
    width: 100%;
  }
}
.image,
.data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  img{
      width: 100%;
  }
}
.data {
  align-items: flex-start;
}
.info {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
  small {
    display: block;
    color: var(--text-secondary);
    margin: 20px 0px;
    span {
      text-decoration-line: line-through;
      -webkit-text-decoration-line: line-through;
      text-decoration-color: var(--text-secondary);
      -webkit-text-decoration-color: var(--text-secondary);
    }
  }
  h3 {
    font-weight: 900;
  }
  h4 {
    color: var(--primary);
    margin: 0;
    font-weight: 900;
  }
}
.brand {
  display: flex;
  align-items: center;
  margin-top: 10px;
  img {
    width: auto;
    height: 30px;
    margin-left: 10px;
  }
}

.stock,
.noStock {
  --color: #000;
  min-width: 40%;
  border: 1px var(--color) solid;
  margin-top: 20px;
  padding: 5px 15px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  color: var(--color);
  h6 {
    margin: 5px 0px;
  }
  span {
    font-size: 0.8rem;
  }
  .icon {
    margin-right: 20px;
  }
}
.stock {
  --color: #3dd468;
}
.noStock {
  --color: #ed3237;
}
.footer {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 992px){
    margin: 20px 0;
  }
}
.options {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
  .description,
  .comprar {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 1px var(--text-secondary) solid;
    border-radius: var(--radius);
    display: flex;
  }
  .comprar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #21cb62;
    border: 0;
    color: #fff;
    svg {
      margin-right: 5px;
      font-size: 1.3rem;
    }
  }
}
.share {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  order: 1;
  a {
    font-size: 1.5rem;
    color: #000;
  }
  @media (min-width: 992px) {
    width: 50%;
    order: 0;
  }
}

.Select {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}
.optionsViewButton {
  width: 100%;
  height: 100%;
  position: relative;
  .optionsCaption {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px var(--text-secondary) solid;
    border-radius: var(--radius);
    padding: 10px;
    color: var(--text-secondary);
    span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    svg {
      transition: all 0.3s;
      width: 10%;
    }
  }
}
.descriptionData {
  width: 100%;
  border: 1px var(--primary) solid;
  border-radius: 0 0 var(--radius) var(--radius);
  padding: 10px 20px;
  display: none;
  grid-template-columns: auto auto;
  color: var(--text-secondary);
  font-weight: bold;
  font-size: 0.8rem;
  p {
    margin: 10px 0;
  }
  .caracteristicas {
    text-align: end;
  }
}
.optionsViewButtonCheck {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 3;
  cursor: pointer;
  &:checked + .optionsViewButton .optionsCaption {
    border: 1px var(--primary) solid;
    border-radius: var(--radius) var(--radius) 0 0;
    color: var(--primary);
    svg {
      transform: rotate(180deg);
    }
  }
  &:checked ~ .descriptionData {
    display: grid;
  }
}
