.cardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  h5,
  h3 {
    font-weight: 900;
  }
}
.ProductCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px var(--primary) solid;
  padding: 10px;
  border-radius: var(--radius) var(--radius) 0 0;
  text-align: start;
  overflow: hidden;
  position: relative;
  color: #333;
  height: 100%;
  @media (min-width: 992px) {
    padding: 22px;
  }
}
.brand{
    width: 2.5rem;
    img{
        width: 100%;
    }
    @media (min-width: 992px){
        width: 4rem;
    }
}
.noStock{
    position: absolute;
    right: 0;
    color: #fff;
    background: #ed3237;
    padding: 5px;
    z-index: 1;
    border-radius: var(--radius);
    margin: 3px;
}
.image {
  width: 100%;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: auto;
    height: 150px;
    @media (min-width: 992px) {
      height: 11rem;
      width: 100%;
    }
  }
}
.priceBefore {
  color: var(--text-secondary);
  text-decoration-line: line-through;
  -webkit-text-decoration-line: line-through;
  text-decoration-color: var(--text-secondary);
  -webkit-text-decoration-color: var(--text-secondary);
}
.percent{
    color:#ed3237;
    display: block;
    font-weight: bold;
    @media (min-width: 992px){
        margin-left: 10px;
        display: inline;
    }
}
.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .type {
    color: #fff;
    background-color: #ed3237;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
    font-size: 1.5rem;
    padding: 8px;
    @media (min-width: 992px) {
      margin-right: -22px;
    }
  }
}

.options {
  height: auto;
  font-size: 0.8rem;
  text-align: center;
  display: flex;
  border-radius: 0 0 var(--radius) var(--radius);
  overflow: hidden;
  a {
    background: #000;
    color: #fff;
    display: flex;
    width: 100%;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    &:first-child {
      background: var(--primary);
    }
    svg {
      font-size: 1rem;
      margin-right: 3px;
    }
  }
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  @media (min-width: 768px){
    font-size: 1rem;
  }
}
.dosRuedas, .tresRuedas, .ambos{
  background: #000;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  margin-right: -10px;
  margin-left: 5px;
  @media (min-width: 992px) {
    margin-right: -22px;
  }
}
.dosRuedas{
  background: #ed3237;
}
.tresRuedas{
  background: var(--primary);
}
.ambos{
  flex-direction: column;
  padding: 0;
  img{
    display: block;
    width: auto;
    height: 30px;
    padding: 0px 5px;
    &:first-child{
      background: #ed3237;
      padding-top: 5px;
    }
    &:last-child{
      background: var(--primary);
      padding-bottom: 5px;
    }
  }
}