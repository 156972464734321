  .Loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    grid-column: 1 / span 3;
  }
  .logo {
    width: 25px;
    height: 25px;
    z-index: 2;
    position: absolute;
  }
  .pulse,
  .pulse:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .pulse {
    font-size: 10px;
    position: relative;
    border-left: 2px solid var(--primary);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  