.header {
  width: 100%;
  position: sticky;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.03);
  z-index: 5;

  & > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 10px;
  }

  img {
    width: 50%;
    @media (min-width: 768px) {
        width: 200px;
    }
  }

  .help {
    font-size: 1.8rem;
    a {
      color: var(--text-secondary);
      display: flex;
      align-items: center;
    }
    small {
      font-size: 1rem;
      margin-left: 5px;
      display: none;
      @media (min-width: 768px){
        display: inline;
      }
    }
  }
  .buscar{
      margin:0px 10px;
      width:60px;
      height: 22px;
  }
}
