.Filters{
    padding: 0 30px;
    height: 100%;
    margin-bottom: 5rem;
}
.clean,
  .search{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 20px 0;
    background: #000;
    border-radius: var(--radius);
    a{
        color: #fff;
    }
    svg{
        margin-left: 10px;
    }
}