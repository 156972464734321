.BackButton{
    margin-left: -30px;
    padding: 5px 20px ;
    margin-bottom: 20px;
    background: #f1f1f1;
    width: fit-content;
    border-radius: 0 var(--radius) var(--radius) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        font-size: 1.2rem;
    }
}