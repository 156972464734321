.Footer{
    background: url(../../../assets/img/fondoFooter.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.brand{
    width: 100%;
    order: 1;
    text-align: center;
    margin-bottom: 10px;
    img{
        width: 100%;
    }
    @media (min-width: 992px){
        order: 0;
        img{
            width: 400px;
        }
    }
}
.contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    a{
        display: block;
        margin: 20px 0;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        svg{
            margin-right: 10px;
            font-size: 1.5rem;
        }
    }
    @media (min-width: 992px){
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    
}
.social{
    order: 2;
 a{
     color: #fff;
     font-size: 2.5rem;
     margin: 20px;
 }
}