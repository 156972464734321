.FilterContainer{
    text-align: start;
    h4{
        margin: 20px 0px;
        color: var(--text-secondary);
        display: flex;
        justify-content: space-between;
        .reorder{
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-weight: 400;
            }
            a{
                font-size: 1.2rem;
                padding: 2px;
                background: var(--text-secondary);
                color: #fff;
                border-radius: var(--radius);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
            }
            
        }
    }
    .filterHeadline{
        margin-bottom: 20px;
    }

}
.filters{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    width: 100%;
    .checkboxContainer{
        margin-bottom: 20px;
        max-height: 30vh;
        overflow: auto;
        @media (min-width: 992px) {
            min-height: 40vh;
            max-height: 40vh;
        }
    }
}
