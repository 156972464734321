@font-face {
  font-family: "gotham";
  src: url(assets/fonts/HomepageBaukasten-Book.eot);
  src: url('assets/fonts/HomepageBaukasten-Book.eot?#iefix') format('embedded-opentype'),
        url(assets/fonts/HomepageBaukasten-Book.woff) format('woff'),
        url(assets/fonts/HomepageBaukasten-Book.woff2) format('woff2'),
        url(assets/fonts/HomepageBaukasten-Book.ttf) format('truetype');
  font-style: normal;
}
@font-face {
  font-family: "gotham";
  src: url(assets/fonts/HomepageBaukasten-Bold.eot);
  src: url('assets/fonts/HomepageBaukasten-Bold.eot?#iefix') format('embedded-opentype'),
        url(assets/fonts/HomepageBaukasten-Bold.woff) format('woff'),
        url(assets/fonts/HomepageBaukasten-Bold.woff2) format('woff2'),
        url(assets/fonts/HomepageBaukasten-Bold.eot) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: "gotham";
  src: url(./assets/fonts/Gotham-Black.otf) format("opentype");
  font-weight: 900;
}
:root {
  --text-secondary: #898989;
  --primary: #236bb3;
  --radius: 5px;
}

* {
  box-sizing: border-box;
  font-family: "gotham", sans-serif;
}
a {
  text-decoration: none;
}
.container {
  max-width: 1320px;
  margin: auto;
}
body {
  color: #333;
  margin: 0;
}
.ReactModal__Overlay {
  background: rgba($color: #474747, $alpha: 0.65) !important;
  backdrop-filter: blur(3px);
  z-index: 9999999999;
}
.ReactModal__Content {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  max-height: 90%;
  position: relative;
  .closeModal {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 1.3rem;
    cursor: pointer;
    span {
      color: #ed3237;
    }
  }
  @media (min-width: 992px) {
    width: 65rem;
    min-width: 60%;
  }
}
.introjs-tooltip-header{
  h1{
    display: flex;
    align-items: center;
    span{
      background: #ed3237;
      width: 30px;
      height: 30px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-right: 10px;
    }
  }
}
.introjs-tooltiptext{
  span{
    color: var(--primary);
    font-weight: bold;
  }
}
.introjs-tooltipbuttons{
  .introjs-button{
    text-shadow: none;
    border-radius: var(--radius);
  }
  .introjs-prevbutton{
    border-color: var(--primary);
    color: var(--primary);
  }
  .introjs-nextbutton, .introjs-donebutton{
    background: var(--primary);
    color: #fff;
    border: 0;
  }
}
.pagination {
  .break{
    border: 0 !important;
    cursor: auto !important;
  }
  .active{
    background: #ed3237;
    border: 0;
    color: #fff;
  }
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f1f1f1;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 5px 0;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4291e0;
}
.sc-dnqmqq{
  display: flex;
  align-items: center;
  margin: auto;
  /* width: 8rem; */
  img{
    width: 100%;
  }
}
.logos{
    width: 8rem !important;
}