.form{
    width: 100%;
    height: 40px;
    border: 1px var(--primary) solid;
    border-radius: var(--radius);
    overflow: hidden;
    margin-bottom: 0px;
    /* position: sticky; */
    @media (min-width: 992px){
        position: initial;
        width: 40rem;
        margin: 0;
    }
    input{
        height: 100%;
        border: 0;
        padding: 0px 20px;
        width: calc(100% - 80px);
        &::placeholder{
            color: var(--primary);
        }
    }
    button{
        height: 100%;
        border: 0;
        background: var(--primary);
        color: #fff;
        width: 80px;
        cursor: pointer;
    }
}