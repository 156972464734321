.Home {
  text-align: center;
  padding: 0px 8px;
  position: relative;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 25% 1fr;
    width: 100%;
    gap: 30px;
    padding: 0 20px;
  }
}
.PrimarySlider{
    padding:10px
}
.SelectVehicle {
    position: sticky;
    top: 64px;
    background: #fff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.03);
    z-index: 3;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left:10px;
    padding-right: 10px;
    @media (min-width: 768px) {
      position: sticky;
      padding-top: 10px;
      padding-left:10%;
      padding-right: 10%;
      top: 80px;
      grid-area: 2/2;
    }
  }
.bhRnqO{
    z-index: 2 !important;
}


.headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 40px;
  h3 {
    font-weight: 900;
  }
  @media (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 0px;
    h3 {
      margin: 0;
    }
  }
  hr {
    width: 100%;
    height: 1px;
    background-color: #cccccc;
    border: 0;
    @media (min-width: 992px) {
      order: 1;
    }
  }
  small {
    color: var(--text-secondary);
  }
}
@media (min-width: 992px) {
  .header {
    grid-area: 1/2;
  }
  .filters {
    grid-row: 2 / span 3;
    grid-column: 1;
    height: fit-content;
  }
  .productsGrid {
    grid-area: 2/2;
  }
}
