.checkboxContainer{
    display: flex;
    align-items: center;
    label{

        cursor: pointer;
    }
    .checkbox{
        margin-right: 10px;
        span{
            font-size: 1.2rem;
        }
    }
    .checked{
        display: none;
    }
    input{
        display: none;
        &:checked + .checkbox .unchecked{
            display: none;
        }
        &:checked + .checkbox .checked{
            display: initial;
        }
    }
}