.ProductsList{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    gap: 8px;
    margin: 20px 0;
    @media (min-width: 768px) {
        grid-template-columns: repeat(3, calc((100% / 3) - 15px));
        gap: 20px;
    }
}