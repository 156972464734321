.Vehicle{
    width: 100%;
    height: 100%;
    padding: 0 30px;
    margin-bottom: 5rem;
}
.headline{
    text-align: center;
    margin-bottom: 30px;
    span{
        color: var(--primary);
    }
}