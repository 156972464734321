.Select {
  position: relative;
  width: 100%;
  height: 40px;
  background: #fff;
}
.optionsViewButton {
  width: 100%;
  height: 100%;
  position: relative;
  .optionsCaption {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px var(--text-secondary) solid;
    border-radius: var(--radius);
    padding: 0px 20px;
    color: var(--text-secondary);
    span {
      width: 100%;
      max-width: 100%;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    svg {
      transition: all 0.3s;
      width: 10%;
    }
  }
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: fit-content;
  background: #fff;
  border: 1px var(--primary) solid;
  border-radius: 0 0 var(--radius) var(--radius);
  text-align: start;
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
  z-index: 5;
  .option {
    position: relative;
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      &:hover + span {
        color: var(--primary);
      }
      &:checked + span {
        background: var(--primary);
        color: #fff;
      }
    }
    span {
      display: block;
      padding: 10px 20px;
      width: 100%;
    }
  }
}
.optionsViewButtonCheck {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 3;
  cursor: pointer;
  &:checked + .optionsViewButton .optionsCaption {
    color: var(--primary);
    border-color: var(--primary);
    border-radius: var(--radius) var(--radius) 0 0;
    svg {
      transform: rotate(180deg);
    }
  }
  &:checked ~ .options {
    display: block;
  }
}
