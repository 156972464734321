.SelectVehicleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.15);
    border-radius: var(--radius);
    height: 50px;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
  .tag {
    display: none;
    background: #ed3237;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: var(--radius) 0 0 var(--radius) !important;
    width: 180%;
    height: 100%;
    svg {
      margin-right: 10px;
      font-size: 1.2rem;
    }
    @media (min-width: 992px) {
      display: flex;
    }
  }
  .clean,
  .search {
    background: #000;

    padding: 10px;
    border-radius: var(--radius);
    cursor: pointer;
    margin: 10px 0;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      svg {
        margin-left: 10px;
      }
    }
    @media (min-width: 992px) {
      margin: 0;
      padding: 0;
      border-radius: 0 var(--radius) var(--radius) 0 !important;
      a {
        background: transparent;
        width: 100%;
        height: 100%;
        justify-content: center;
      }
    }
  }
  .search {
    @media (min-width: 992px) {
      display: none;
    }
  }
  .year,
  .type,
  .model {
    margin-bottom: 30px;
    small {
      display: block;
      color: var(--text-secondary);
      margin-bottom: 10px;
      @media (min-width: 992px) {
        display: none;
      }
    }
    @media (min-width: 992px) {
      margin: 0;
      min-width: 20%;
      max-width: 20%;
      & > div {
        height: 100%;
        input + div > div {
          border-radius: 0;
        }
      }
    }
  }
}
