.Pagination{
    display: flex;
    justify-content: center;
    border-top: 1px var(--text-secondary) solid;
    @media (min-width: 992px){
        justify-content: flex-end;
    }
    ul{
        display: flex;
        gap: 5px;
        padding: 0;
        li{
            list-style: none;
            border: 1px var(--primary) solid;
            border-radius: var(--radius);
            cursor: pointer;
            color: var(--primary);
            a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            }
        }
    }
}