.Filter{
    width: 30%;
    background: #fff;
    border: 1px var(--primary) solid;
    border-radius: var(--radius);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary);
}