/* .slider{
    margin: 30px 0;
    margin-top: 100px;
    display: none;
    @media (min-width: 992px){
        display: block;
    }
} */
@media (min-width: 768px){
    .slider{
        padding-left: 30px;
        padding-right: 30px;
    }
}